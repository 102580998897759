import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

const DeviceRegistration = lazy(() => import('../../container/device/deviceRegistration'));
const DeviceList = lazy(() => import('../../container/device/deviceList'));
const DeviceUpdate = lazy(() => import('../../container/device/deviceUpdate'));
const DeviceDetails = lazy(() => import('../../container/device/DeviceDetail/deviceDetails'));
const AssignDevice = lazy(() => import('../../container/device/assignDevice'));
function OwnerRoute() {
  return (
    <Routes>
      <Route path="registration" element={<DeviceRegistration />} />
      <Route path="assign" element={<AssignDevice />} />
      <Route path="list" element={<DeviceList />} />
      <Route path="detail/:deviceId" element={<DeviceDetails />} />
      <Route path="update/:deviceId" element={<DeviceUpdate />} />
    </Routes>
  );
}

export default OwnerRoute;
