import { Spin } from 'antd';
import React, { Suspense, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

import Pages from './pages';
import Farmer from './farmer';
import FarmHistory from './farmHistory';
import Soil from './soil';
import Farm from './farm';
import Blog from './Blog';
import Farmhub from './farmhub';
import Stock from './stock';
import Report from './report';
import Owner from './owner';
import Device from './device';
import Subscription from './subscription';
import withAdminLayout from '../../layout/withAdminLayout';

const Admin = React.memo(() => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Suspense
      fallback={
        <div className="spin">
          <Spin />
        </div>
      }
    >
      <Routes>
        <Route index path="/*" element={<Pages />} />
        <Route path="pages/*" element={<Pages />} />
        <Route path="farmhub/*" element={<Farmhub />} />
        <Route path="farmer/*" element={<Farmer />} />
        <Route path="farm/*" element={<Farm />} />
        <Route path="stock/*" element={<Stock />} />
        <Route path="farmHistory/*" element={<FarmHistory />} />
        <Route path="soil/*" element={<Soil />} />
        <Route path="blog/*" element={<Blog />} />
        {/* report */}
        <Route path="report/*" element={<Report />} />
        <Route path="owner/*" element={<Owner />} />
        <Route path="device/*" element={<Device />} />
        <Route path="subscription/*" element={<Subscription />} />
        <Route path="soil-test-results/*" element={<Subscription />} />
      </Routes>
    </Suspense>
  );
});

export default withAdminLayout(Admin);
