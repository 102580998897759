import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

const AgentList = lazy(() => import('../../container/owner/agentList'));
const DeviceList = lazy(() => import('../../container/owner/deviceList'));
const OwnerRegistration = lazy(() => import('../../container/owner/ownerRegistration'));
const AgentRegistration = lazy(() => import('../../container/owner/agentRegistration'));
const OwnerList = lazy(() => import('../../container/owner/ownerList'));
const OwnerUpdate = lazy(() => import('../../container/owner/ownerUpdate'));
const OwnerDetails = lazy(() => import('../../container/owner/OwnerDetail/ownerDetails'));
function OwnerRoute() {
  return (
    <Routes>
      <Route path="registration" element={<OwnerRegistration />} />
      <Route path="list" element={<OwnerList />} />
      <Route path="detail/:id" element={<OwnerDetails />} />
      <Route path="update/:id" element={<OwnerUpdate />} />
      <Route path="agent-registration" element={<AgentRegistration />} />
      <Route path="agent-list/:id" element={<AgentList />} />
      <Route path="device-list/:id" element={<DeviceList />} />
    </Routes>
  );
}

export default OwnerRoute;
