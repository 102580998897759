import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

const SubscriptionList = lazy(() => import('../../container/subscription/subscriptionList'));
const SubscriptionRegistration = lazy(() => import('../../container/subscription/subscriptionRegistration'));
const SubscriptionDetails = lazy(() => import('../../container/subscription/SubscriptionDetail/subscriptionDetails'));
const SubscriptionUpdate = lazy(() => import('../../container/subscription/subscriptionUpdate'));
const UpgradeSubscription = lazy(() => import('../../container/subscription/subscriptionUpgrade'));
const DowngradeSubscription = lazy(() => import('../../container/subscription/subscriptionDowngrade'));
const RenewSubscription = lazy(() => import('../../container/subscription/subscriptionRenew'));

function SubscriptionRoute() {
  return (
    <Routes>
      <Route path="add" element={<SubscriptionRegistration />} />
      <Route path="list" element={<SubscriptionList />} />
      <Route path="detail/:id" element={<SubscriptionDetails />} />
      <Route path="update/:id" element={<SubscriptionUpdate />} />
      <Route path="upgrade/:id" element={<UpgradeSubscription />} />
      <Route path="downgrade/:id" element={<DowngradeSubscription />} />
      <Route path="renew/:id" element={<RenewSubscription />} />
    </Routes>
  );
}

export default SubscriptionRoute;
